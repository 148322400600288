import { useCallback, useEffect, useState } from "react";
import { useRegion, useStatesCodes } from "@with-nx/region";
import {
  Database,
  Formatter,
  Pipedrive,
  useBusinessTypeName,
  useMobile,
  useService,
} from "@with-nx/hooks-n-helpers";
import { Row } from "antd";
import { Box } from "simple-effing-primitive-layout";
import Toast from "react-hot-toast";
import moment from "moment-timezone";

interface StoredValues {
  step: number;
  values: GetStartedDetails;
}

export interface SteppedGetStartedFormProps {
  initial?: GetStartedDetails;
  value?: GetStartedDetails;
  change?: (details: ContactDetails, valid: boolean) => void;
  action?: "get-started" | string;
  referrer?: string;
  submit?: () => Promise<void>;
  loading?: boolean;
  align?: "center" | "left";
  justCalendar?: boolean;
}

import { DesignedButton, Rule } from "@with-nx/simple-ui/atoms";
import { Stepper } from "../stepper";
import { Modal } from "@with-nx/simple-ui/molecules";
import {
  buildMeta,
  debounce,
  getComplementaryValidationValue,
  getDefaultMessageProducts,
  getRegion,
  getSubject,
  validators,
} from "./helpers";
import { ContactDetails, ContactMode, views } from "./types";
import { useRouter } from "next/router";
import { GetStartedDetails } from "./contact-form";
import { MeetingScheduler } from "../meeting-scheduler/meeting-scheduler";
import { FormFields } from "./form-fields";
import { SelfOnboard } from "../self-onboard/self-onboard";

const temporaryAccountExistCheck = async (email: string) => {
  try {
    const makeRequestToBackstage = useService("accounts", {
      cache: 60_000_000,
      bypass: true,
    });

    const request = await makeRequestToBackstage("POST", "/api/v2/account", {
      account: {
        email,
      },
    });

    if (
      (request as unknown as any)?.error?.includes(
        "Email has already been taken"
      ) ||
      (request as unknown as any)?.errors?.includes(
        "Email has already been taken"
      )
    ) {
      return true;
    }
    return false;
  } catch (error) {
    return true;
  }
};

export const SteppedGetStartedForm = ({
  initial,
  change,
  submit,
  action,
  referrer,
  align,
  justCalendar,
  value,
}: SteppedGetStartedFormProps) => {
  const businessTypeNames = useBusinessTypeName();
  const [values, _values] = useState<GetStartedDetails>({
    ...initial,
  });

  const [errors, _errors] = useState<Record<string, string | boolean>>({});

  const [valid, _valid] = useState(true);
  const [modal, _modal] = useState("");

  const [step, _step] = useState(0);
  const [view, _view] = useState<views>(justCalendar ? "calendly" : "steps");

  const [loading, _loading] = useState(false);
  const [missingFields, _missingFields] = useState<string[]>([]);

  const region = useRegion();
  const states = useStatesCodes(values.region === "🇨🇦 Canada" ? "CA" : "US");

  const mobile = useMobile();
  const router = useRouter();

  const validateFields = (fieldsToValidate?: string[]) => {
    let newErrors = {
      ...errors,
    };

    const keys = fieldsToValidate?.length
      ? Object.keys(validators).filter((field) =>
          fieldsToValidate?.includes(field)
        )
      : Object.keys(validators);

    for (const field of keys) {
      const value = values[field as keyof GetStartedDetails];

      const validation = validators[field]?.(
        value,
        getComplementaryValidationValue(field, values)
      );

      const hasError =
        (typeof validation === "string" && validation?.length > 0) ||
        validation === false;

      newErrors = {
        ...newErrors,
        [field]: !hasError
          ? ""
          : typeof validation === "string"
          ? validation
          : true,
      };
    }

    return newErrors;
  };

  const mode: ContactMode =
    (router.query?.["mode"] as ContactMode) || "get-started";

  const subject = getSubject(mode);

  const handleSubmit = useCallback(async () => {
    if (loading) return;

    _modal("");

    const errors = validateFields();
    const fields = Object.keys(errors).filter((key) => !!errors[key]);
    const valid = Object.values(errors)?.some((error) => !!error) === false;

    _valid(valid);
    _errors(errors);
    _missingFields(fields);

    const fieldsExceptCustomerType = fields?.filter(
      (field) => field !== "customer_type"
    );

    if (fieldsExceptCustomerType?.length > 2) {
      _missingFields(fieldsExceptCustomerType);
      _modal("more");
      return;
    }

    if (fieldsExceptCustomerType?.length >= 1) {
      _missingFields(fieldsExceptCustomerType);
      _modal("fields");
      return;
    }

    if (fields?.length === 1 && fields?.includes("customer_type")) {
      _modal("customer_type");
      return;
    }

    if (Formatter.gup("force_calendly") === "1") {
      _view("calendly");
      return;
    }

    try {
      _loading(true);
      let error: false | string = false;

      const meta = buildMeta(values, businessTypeNames.search);

      const pipedrive = await Pipedrive.inquiry({
        isReturningCustomer: values?.customer_type === "Returning Customer",
        email: values?.email || "",
        name: values?.name ? values?.name?.split(" ")?.[0] || values?.name : "",
        surname: values?.name
          ? values?.name
              ?.split(" ")
              ?.splice(1, values?.name?.split(" ")?.length)
              ?.join(" ") || "*"
          : "",
        phone: values?.phone_number || "",
        region: getRegion(values),
        state: values?.state,
        show: values?.show || "",
        message: values?.message || "N/A",
        openingNight: values?.opening_night || "",
        closingNight: values?.closing_night || "",
        additionalData: meta,
        businessType: Number(values?.organization_type || "0"),
        organization: values?.organization || "",
        subject: subject,
      });

      if (pipedrive?.["error"]) {
        error = pipedrive?.["message"];
        throw error;
      }

      const exists = await temporaryAccountExistCheck(
        value?.email || String(values?.email)
      );

      if (!exists) {
        _view("calendly");
      } else {
        submit?.().then(() => {
          _view("onboard");
        });
      }
    } catch (error) {
      console.error("Contact Error", error);
      Toast.error("Something went wrong. Please try again later.");
      throw error;
    } finally {
      _loading(false);
    }
  }, [values, loading, router, subject]);

  const clearFieldError = (field: string) => {
    _errors((previousErrors) => ({
      ...previousErrors,
      [field]: "",
    }));
  };

  const validate = (field: string, value?: string) => {
    if (validators[field]) {
      const newValue = value || values[field as keyof GetStartedDetails];
      const validation = validators[field](
        newValue,
        getComplementaryValidationValue(field, values)
      );

      if (validation === false || validation?.length > 0) {
        return typeof validation === "string" ? validation : true;
      }
    }

    return "";
  };

  const handleChange = (field: string, value: string | string[]) => {
    const newValues = { ...values, [field]: value };
    _values(newValues);

    if (
      ["opening_night", "closing_night", "customer_type", "products"].includes(
        field
      )
    ) {
      const validation = validate(field, value as string);

      _errors((prevErrors) => ({
        ...prevErrors,
        [field]: validation,
      }));
    }

    Database.set("get-started-form", {
      step,
      values: newValues,
    });
  };

  const debouncedHandleBlur = debounce((field: string) => {
    const validation = validate(field);
    _errors((prevErrors) => ({
      ...prevErrors,
      [field]: validation,
    }));
  }, 100);

  const handleFocus = (field: string) => clearFieldError(field);

  const submitModalContent = () => {
    if (modal === "more") {
      return (
        <>
          <Rule
            parse={`!lm ${mobile ? "ta:center pl:30 pr:30" : ""}`}
            weight="500"
          >
            Would you be able to fill{" "}
            <Rule parse="!lm" weight="700">
              out all the missing information
            </Rule>{" "}
            below please?
          </Rule>
          <Box top={30}>
            <DesignedButton
              icon="left"
              label="Back to the Form"
              size="large"
              press={() => {
                const isFirstStepMissingField = firstFields.some((field) =>
                  missingFields?.includes(field)
                );
                _step(isFirstStepMissingField ? 0 : 1);
                _modal("");
              }}
              properties={{
                native: {
                  cypress: "back-to-form-button",
                },
              }}
            />
          </Box>
        </>
      );
    }

    const missingValid = !missingFields.some((field) => !!errors[field]);
    const submitButton = (
      <Box top={30}>
        <DesignedButton
          label="Submit Request"
          size="large"
          press={handleSubmit}
          disable={
            missingValid ? false : ["Make sure all fields are filled out."]
          }
          loading={loading}
          properties={{
            native: {
              cypress: "modal-submit-button",
            },
          }}
        />
      </Box>
    );

    if (modal === "fields") {
      return (
        <>
          <Rule
            parse={`!lm ${mobile ? "ta:center pl:30 pr:30" : ""}`}
            weight="500"
          >
            Would you be able to fill{" "}
            <Rule parse="!lm" weight="700">
              following information
            </Rule>{" "}
            down below please?
          </Rule>

          <Box
            parse={`d:flex j:center fd:column c:#FFF pa:30 mt:30 ${
              mobile
                ? "w:100% br:none pl:20 pr:20 pb:30"
                : "pl:60 pr:60 pb:50 mw:640 br:10 mh:276"
            }`}
          >
            <Row>
              {missingFields.map((field) => (
                <FormFields
                  field={field}
                  mobile={mobile}
                  values={values}
                  span={24}
                  handleFocus={handleFocus}
                  debouncedHandleBlur={debouncedHandleBlur}
                  handleChange={handleChange}
                  clearFieldError={clearFieldError}
                  errors={errors}
                  regions={region.regions}
                  states={states}
                />
              ))}
            </Row>
          </Box>
          {submitButton}
        </>
      );
    }

    if (modal === "customer_type") {
      return (
        <>
          {
            <FormFields
              field="customer_type"
              mobile={mobile}
              values={values}
              handleFocus={handleFocus}
              debouncedHandleBlur={debouncedHandleBlur}
              handleChange={handleChange}
              clearFieldError={clearFieldError}
              errors={errors}
              regions={region.regions}
              states={states}
            />
          }
          {submitButton}
        </>
      );
    }

    return null;
  };

  const firstFields = [
    "name",
    "email",
    "phone_number",
    "organization",
    "organization_type",
    "region",
    "state",
  ];
  const secondFields = ["show", "opening_night", "closing_night"];

  const [date, _date] = useState("");
  const [time, _time] = useState("");
  const [timeZone, _timeZone] = useState<string>(moment.tz.guess());
  const [confirmModal, _confirmModal] = useState(false);
  const [scheduling, _scheduling] = useState(false);
  const [schedulingError, _schedulingError] = useState("");

  const createMeetingEvent = async () => {
    _scheduling(true);
    _schedulingError("");

    const start = moment(time);
    const end = moment(time).add(30, "minutes");

    const times = {
      start: {
        dateTime: start,
        timeZone,
      },
      end: {
        dateTime: end,
        timeZone,
      },
    };

    const formattedDate = moment(date).format("dddd, MMMM D, YYYY");

    const formattedDateAndTime = `${start.format("LT")} - ${end.format(
      "LT"
    )} (${timeZone?.replace(/_/g, " ")}) on ${formattedDate}`;

    const description = () => {
      return [
        `Event Name: Meet with a member of our team!`,
        `Event Description: We would love for you to join us in a Google Meet where we can address your questions about our resources. Can't find a time that works with your schedule? Feel free to email us at hello@broadwaymedia.com so we can come up with a solution.`,
        `Date & Time: ${formattedDateAndTime}`,
        `Location: This is a Google Meet web conference. You can join this meeting from your computer, tablet, or smartphone.`,
        values?.show ? `Production/Show Title: ${values.show}` : "",
        values?.show ? `Opening Night: ${values.opening_night}` : "",
        values?.show ? `Closing Night: ${values.closing_night}` : "",
        values?.phone_number
          ? `Back-up Phone Number in case we need to reach you outside of the Google Meet: ${values.phone_number}`
          : "",
        values?.products && values.products.length > 0
          ? `What will we be discussing in this meeting? Please check all that apply: \n${values.products.join(
              "\n"
            )}`
          : "",
        values?.message
          ? `Are there any questions in particular that you would like us to address?: ${values.message}`
          : "",
      ]
        .filter(Boolean)
        .join("\n\n");
    };

    const event = {
      summary: `${values?.name}: Meet with a member of our team! with Broadway Media`,
      attendees: [
        {
          email: value?.email || values?.email,
          displayName: value?.name || values?.name,
        },
      ],
      description: description(),
      start: times.start,
      end: times.end,
    };

    try {
      const response = await fetch("/api/google-calendar", {
        method: "POST",
        body: JSON.stringify({ event, formattedDateAndTime }),
      });

      if (response.ok) {
        _confirmModal(false);

        localStorage.setItem(`meeting-${date}-${time}`, "yes");

        if (justCalendar) {
          router.push("/contact/calendar/thank-you");
          return;
        }

        const accountExists = await temporaryAccountExistCheck(
          String(value?.email || values.email)
        );

        if (!accountExists) {
          _view("auth");
        } else {
          _view("onboard");
        }
      } else {
        const json = await response.json();
        _schedulingError(json?.error);
      }
    } catch (error) {
      let message;

      if (error instanceof Error) message = error.message;
      else message = String(error);

      _schedulingError(message);
    } finally {
      _scheduling(false);
    }
  };

  const getCalendarStepAction = () => {
    if (view !== "calendly") {
      return {
        label: "Next",
        onClick: () => {
          handleSubmit();
        },
        hideIcon: false,
      };
    }

    if (view === "calendly" && !date) {
      return {
        label: "Skip",
        hideIcon: false,
        loading: loading,
        onClick: async () => {
          const accountExists = await temporaryAccountExistCheck(
            String(value?.email || values.email)
          );
          if (!accountExists) {
            _view("auth");
          } else {
            _view("onboard");
          }
        },
      };
    }

    return {
      label: "Next",
      onClick: () => _confirmModal(true),
      hideIcon: false,
      loading: loading,
    };
  };

  const dateTimezone = moment.tz(time, timeZone);
  const formattedTime = dateTimezone.format(
    dateTimezone.minutes() === 0 ? "HH a" : "LT a"
  );

  const confirmFormattedDate = `${moment(date).format(
    `MMMM D, YYYY [at]`
  )} ${formattedTime} ${timeZone} (${dateTimezone.utc().format("LT [UTC]")})`;

  const steps = [
    {
      title: "Contact Information",
      render: () => {
        return (
          <Row gutter={[30, 10]}>
            {firstFields
              .filter((field) => field !== "state")
              .map((field) => (
                <FormFields
                  field={field}
                  mobile={mobile}
                  values={values}
                  handleFocus={handleFocus}
                  debouncedHandleBlur={debouncedHandleBlur}
                  handleChange={handleChange}
                  clearFieldError={clearFieldError}
                  errors={errors}
                  regions={region.regions}
                  states={states}
                />
              ))}
          </Row>
        );
      },
    },
    {
      title: "Show Information",
      render: () => {
        return (
          <Row gutter={[30, 20]}>
            {secondFields.map((field) => (
              <FormFields
                field={field}
                mobile={mobile}
                values={values}
                handleFocus={handleFocus}
                debouncedHandleBlur={debouncedHandleBlur}
                handleChange={handleChange}
                clearFieldError={clearFieldError}
                errors={errors}
                regions={region.regions}
                states={states}
              />
            ))}
          </Row>
        );
      },
      action: {
        label: "Next",
        onClick: () => {
          _step(2);
          _view("steps");
        },
        hideIcon: false,
      },
    },
    {
      title: (
        <>
          Additional
          <br />
          Information
          {view === "calendly" && (
            <li
              style={{
                color: "var(--grey-60)",
                marginTop: 5,
              }}
            >
              Schedule a Meeting
            </li>
          )}
          {view === "auth" && (
            <li
              style={{
                color: "var(--grey-60)",
                marginTop: 5,
              }}
            >
              Create Account
            </li>
          )}
        </>
      ),
      render: () => {
        const fields = ["message", "products"];

        if (["auth", "onboard"].includes(view))
          return <SelfOnboard view={view} _view={_view} values={values} />;

        if (view === "calendly") {
          return (
            <MeetingScheduler
              large={justCalendar}
              date={date}
              time={time}
              timeZone={timeZone}
              _timeZone={_timeZone}
              _date={_date}
              _time={_time}
            />
          );
        }

        return (
          <Row gutter={[30, 20]}>
            {fields.map((field) => (
              <FormFields
                field={field}
                mobile={mobile}
                values={values}
                handleFocus={handleFocus}
                debouncedHandleBlur={debouncedHandleBlur}
                handleChange={handleChange}
                clearFieldError={clearFieldError}
                errors={errors}
                regions={region.regions}
                states={states}
              />
            ))}
          </Row>
        );
      },
      action: getCalendarStepAction(),
      hideButtons:
        justCalendar && (!date || !time)
          ? true
          : ["onboard", "auth"].includes(view),
    },
  ];

  useEffect(() => {
    (async () => {
      if (justCalendar) {
        return;
      }

      const stored = await Database.get<StoredValues>("get-started-form");
      if (stored) {
        _step(stored.step);
        _values(stored.values);
      }
    })();
  }, [justCalendar]);

  useEffect(() => {
    const defaultValues = getDefaultMessageProducts(action, referrer);

    if (defaultValues) {
      _values((v) => ({
        ...v,
        message: defaultValues.message,
        products: [
          ...(values.products || []),
          ...(defaultValues.products || []),
        ],
      }));
    }
  }, [action, referrer]);

  useEffect(() => {
    change?.(values, valid);
  }, [values, valid]);

  return (
    <>
      {justCalendar ? undefined : (
        <Box
          parse={`d:flex fd:column a:${
            mobile || align === "left" ? "flex-start" : "center"
          }`}
          bottom={42}
        >
          <FormFields
            field="customer_type"
            mobile={mobile}
            values={values}
            handleFocus={handleFocus}
            debouncedHandleBlur={debouncedHandleBlur}
            handleChange={handleChange}
            clearFieldError={clearFieldError}
            errors={errors}
            regions={region.regions}
            states={states}
          />
        </Box>
      )}

      <Stepper
        hideBullets={justCalendar}
        hidePreviousButton={justCalendar}
        hideSidebar={justCalendar}
        steps={steps}
        current={justCalendar ? 2 : step}
        _current={justCalendar ? () => {} : _step}
      />

      <Modal
        centered
        open={!!modal}
        onClose={() => _modal("")}
        mobile={mobile}
        style={
          mobile
            ? {
                marginLeft: 30,
                marginRight: 30,
              }
            : undefined
        }
      >
        <Box
          parse={`d:flex fd:column a:center j:center pt:50 ${
            mobile ? "mt:8 mb:30 ml:-24 mr:-24" : "mb:50"
          }`}
        >
          <Rule
            parse={`!hl ${mobile ? "ta:center" : ""}`}
            weight="700"
            bottom={10}
          >
            Before You Submit
          </Rule>
          {submitModalContent()}
        </Box>
      </Modal>

      <Modal
        centered
        open={!!confirmModal}
        onClose={() => _confirmModal(false)}
        mobile={mobile}
        style={
          mobile
            ? {
                marginLeft: 30,
                marginRight: 30,
              }
            : undefined
        }
      >
        <Box
          parse={`d:flex fd:column a:center j:center pt:50 ${
            mobile ? "mt:8 mb:30 ml:-24 mr:-24" : "mb:50"
          }`}
        >
          <Rule
            parse={`!_h2 ${mobile ? "ta:center" : ""}`}
            weight="700"
            bottom={10}
          >
            Confirm Date & Time
          </Rule>

          {schedulingError ? (
            <Rule parse="!_bxl mb:30">{schedulingError}</Rule>
          ) : !date || !time ? (
            <Rule parse="!_bxl mb:30">Please choose an hour</Rule>
          ) : (
            <Rule parse="!_bxl mb:30">{confirmFormattedDate}</Rule>
          )}

          {!date || !time ? (
            <DesignedButton
              label="OK"
              press={() => _confirmModal(false)}
              theme="primary"
            />
          ) : (
            <DesignedButton
              label="Confirm"
              press={createMeetingEvent}
              theme="primary"
              loading={scheduling}
            />
          )}
        </Box>
      </Modal>
    </>
  );
};
